<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />
         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">         
            <template v-for="(item, key) in list_check_box">               
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                      <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3> 
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    
                    <!-- Section Detail Start -->
                    <!--General information start-->
                    <div class="content-onglets table" v-if="key == 'detail_general_information' && item.show">
                        <table class="comparateur2 border-top-gray">
                            <tr v-if="data.guidance_id">
                                <th width="20%" class="text-left bg-white">Project number</th>
                                <td class="bg-white text-left"> {{data.guidance_id }} </td>
                            </tr>
                            <tr v-if="data.status">
                                <th width="20%" class="text-left bg-white">Review status</th>
                                <td class="bg-white text-left"> {{ data.status }} </td>
                            </tr>
                        </table>
                    </div>
                    <!--General information end-->

                    <!--detail_indication start-->
                    <Indication v-if="key == 'detail_indication' && item.show"/>
                    <!--detail_indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show" />
                    <!-- treatment_line end -->

                    <!--MA spec start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_MaSpac' && item.show">
                        <table class="comparateur2 text-left">
                            <tr colspan="3">
                                <td class="bold"><strong> {{$t('TRS_AMM')}} </strong></td>
                                <td> {{data.cadth['cadth_type_amm'] ?  $t('ceesp_type_amm_' + data.cadth['cadth_type_amm']) : '-'}} </td>
                            </tr>
                        </table>
                    </div>
                    <!--MA spec end-->

                    <!-- detail_Conclusion start-->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />
                    <!-- detail_Conclusion end-->

                    <!--Link Recommendation start-->   
                    <div class="content-onglets table" v-else-if="key == 'detail_recommendation' && item.show">
                        <p v-if="data.cadth['recommendation_manual']" v-html="data.cadth['recommendation_manual']"></p>                       
                        <table class="comparateur2 table_result" v-if="data.cadth['clinical_criteria']">
                            <tbody>
                                <tr>
                                    <td><strong> {{$t('clinical_criteria')}} </strong></td>
                                </tr>
                                <tr>
                                    <td> 
                                        <span v-html="data.cadth['clinical_criteria'] ? data.cadth['clinical_criteria'] : '-'"></span> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="space"></div>
                        <template v-if="data.cadth['condition']">
                            <p>
                                <strong> {{$t('condition')}} </strong>
                                <span v-html="data.cadth['condition'] ? data.cadth['condition'] : '-'"></span> 
                            </p>                           
                        </template>
                    </div>
                    <!--Link Recommendation end-->
                    
                    <!--Link Reasons for the Recommendation start-->
                    <div class="content-onglets" v-else-if="key == 'detail_reasons_recommendation' && item.show">
                        <p v-html="data.cadth['reasons_recommendation']"></p>
                    </div>
                    <!--Link Reasons for the Recommendation end-->

                    <!-- Implementation considerations start -->
                    <div class="content-onglets" v-else-if="key == 'detail_implementation' && item.show">
                        <p v-html="data.cadth['implementation']"></p>
                    </div>
                    <!-- Implementation considerations end -->

                    <!--Link Of note start-->
                    <div class="content-onglets" v-else-if="key == 'detail_ofNote' && item.show">
                        <p v-html="data.cadth['of_note']"></p>
                    </div>
                    <!--Link Of note end-->

                    <!--Link Background start-->
                    <div class="content-onglets" v-else-if="key == 'detail_background' && item.show">
                        <p v-html="data.cadth['background']"></p>
                    </div>
                    <!--Link Background end-->

                    <!--Link Submission History start-->
                    <div class="content-onglets" v-else-if="key == 'detail_submissionHistory' && item.show">
                        <p v-html="data.cadth['submission_history']"></p>
                    </div>
                    <!--Link Submission History end-->

                    <!--Link Recommendation start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_recommendationSummary' && item.show">
                        <div class="drug-info-container gray-box">
                            <div class="gray-box-inner">
                                <template v-if="data.cadth['summary_cdec']">
                                    <div class="space"></div> 
                                    <div class="table_result textJustify">
                                        <span v-html="data.cadth['summary_cdec']"></span>
                                    </div>
                                </template>
                                <template v-if="data.cadth['comparison_of_cdec_recommendation']">
                                    <div class="space"></div>
                                    <div class=""><strong>Comparison of CDEC Recommendations</strong></div>
                                    <div class="table_result textJustify">
                                        <span v-html="data.cadth['comparison_of_cdec_recommendation']"></span>
                                    </div>
                                </template>
                                <template v-if="data.cadth['patient_input_information']">
                                    <div class="space"></div>
                                    <div class=""><strong> {{$t('patient_input_information')}} </strong></div>
                                    <div class="table_result textJustify">
                                        <span v-html="data.cadth['patient_input_information']"></span>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_clinical_trials']">
                                    <div class="space"></div>
                                    <div class=""><strong>{{$t('summary_cdec_clinical_trials')}} </strong></div>
                                    <div class="table_result textJustify">
                                        <span v-html="data.cadth['summary_cdec_clinical_trials']"></span>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_outcomes']">
                                    <div class="space"></div>
                                    <div class=""><strong> {{$t('summary_cdec_outcomes')}} </strong></div>
                                    <div class="table_result textJustify">
                                        <span v-html="data.cadth['summary_cdec_outcomes']"></span>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_manufacturer']">
                                    <div class="space"></div>
                                    <div class=""><strong> {{$t('summary_cdec_manufacturer')}} </strong></div>
                                    <div class="table_result textJustify">
                                        <span v-html="data.cadth['summary_cdec_manufacturer']"></span>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_network']">
                                    <div class="space"></div>
                                    <div class=""><strong> {{$t('summary_cdec_network')}} </strong></div>
                                    <div class="table_result textJustify">
                                        <span v-html="data.cadth['summary_cdec_network']"></span>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_extension']">
                                    <div class="space"></div>
                                    <div class=""><strong> {{$t('summary_cdec_extension')}} </strong></div>
                                    <div class="table_result textJustify">
                                        <span v-html="data.cadth['summary_cdec_extension']"></span>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_additional']">
                                    <div class="space"></div>
                                    <div class=""><strong> {{$t('summary_cdec_additional')}} </strong></div>
                                    <div class="table_result textJustify">
                                        <span v-html="data.cadth['summary_cdec_additional']"></span>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_systematic']">
                                    <div class="space"></div>
                                    <div class=""><strong> {{$t('summary_cdec_systematic')}} </strong></div>
                                    <div class="table_result textJustify">
                                        <span v-html="data.cadth['summary_cdec_systematic']"></span>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_pharmacokinetic']">
                                    <div class="space"></div>
                                    <div class=""><strong> {{$t('summary_cdec_pharmacokinetic')}} </strong></div>
                                    <div class="table_result textJustify">
                                        <span v-html="data.cadth['summary_cdec_pharmacokinetic']"></span>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_results']">
                                    <div class="space"></div>
                                    <div class=""><strong> {{$t('summary_cdec_results')}} </strong></div>
                                    <div class="table_result textJustify">
                                        <span v-html="data.cadth['summary_cdec_results']"></span>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_results_efficacy_effectiveness']">
                                    <div class="space"></div>
                                    <div style="padding-left: 50px;">
                                        <div class=""><strong> {{$t('summary_cdec_results_efficacy_effectiveness')}} </strong></div>
                                        <div class="table_result textJustify" style="padding-left: 15px;">
                                            <span v-html="data.cadth['summary_cdec_results_efficacy_effectiveness']"></span>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_results_harms']">
                                    <div class="space"></div>
                                    <div style="padding-left: 50px;">
                                        <div class=""><strong> {{$t('summary_cdec_results_harms')}} </strong></div>
                                        <div class="table_result textJustify" style="padding-left: 15px;">
                                            <span v-html="data.cadth['summary_cdec_results_harms']"></span>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_results_indirect_treatment_comparison']">
                                    <div class="space"></div>
                                    <div style="padding-left: 50px;">
                                        <div class=""><strong> {{$t('summary_cdec_results_indirect_treatment_comparison')}} </strong></div>
                                        <div class="table_result textJustify" style="padding-left: 15px;">
                                            <span v-html="data.cadth['summary_cdec_results_indirect_treatment_comparison']"></span>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_results_extrapolations']">
                                    <div class="space"></div>
                                    <div style="padding-left: 50px;">
                                        <div class=""><strong>Extrapolation</strong></div>
                                        <div class="table_result textJustify" style="padding-left: 15px;">
                                            <span v-html="data.cadth['summary_cdec_results_extrapolations']"></span>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="data.cadth['summary_cdec_results_bioequivalences']">
                                    <div class="space"></div>
                                    <div style="padding-left: 50px;">
                                        <div class=""><strong> {{$t('summary_cdec_results_bioequivalences')}} </strong></div>
                                        <div class="table_result textJustify" style="padding-left: 15px;">
                                            <span v-html="data.cadth['summary_cdec_results_bioequivalences']"></span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                 <!--Link Recommendation end-->

                <!--EssaisClinique start-->
                <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                <!--EssaisClinique end-->   

                <!--Link Cost and Cost-Effectiveness start-->
                <div class="content-onglets" v-else-if="key == 'detail_costCostEffectiveness' && item.show">
                    <p v-html="data.cadth['cost_cost_effectiveness']"></p>
                </div>
                <!--Link Cost and Cost-Effectiveness end-->  

                <!--Request for clarification start--> 
                <div class="content-onglets" v-else-if="key == 'detail_requestForClarification' && item.show">
                    <p v-html="data.cadth['request_for_clarification']"></p>
                </div>
                <!--Request for clarification end--> 

                <!-- detail_EvaluationEconomic  -->
                <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.cadth.evaluation_economic" />

                <!-- detail_EconomicEvaluation -->
                <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" /> 

                <!--Link Other Discussion Points start-->
                <div class="content-onglets" v-else-if="key == 'detail_otherDiscussionPoint' && item.show">
                    <p v-html="data.cadth['other_discussion_point']"></p>
                </div>
                <!--Link Other Discussion Points end-->

                <!--Link Research Gaps start-->
                <div class="content-onglets" v-else-if="key == 'detail_researchGaps' && item.show">
                     <p v-html="data.cadth['research_gaps']"></p>
                </div>
                <!--Link Research Gaps end-->

                <!--Key Milestones start-->
                <div class="content-onglets table" v-else-if="key == 'detail_key_milestones' && item.show">
                    <table class="comparateur2 border-top-gray">
                        <tbody>
                            <tr v-for="(value, key) in key_milestones" :key="'key_milestones_'+key">
                                <td style="text-align: left;">
                                    <strong v-html="$t('cadth.key_milestones.'+key)"></strong>
                                    <p style="margin-bottom: 0px;" v-if="value['clarification']">
                                        {{$t('Clarification')}} :{{ value['clarification']}} 
                                    </p>
                                </td> 
                                <td width="30%" style="vertical-align: middle;">
                                    {{value['date'] ? DDMMMYY(value['date']) : '-'}} 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--Key Milestones end-->

                <!--PDF start-->
                <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist" />
                <!--PDF end-->

                <!--Link Listing request start--> 
                <div class="content-onglets" v-else-if="key == 'detail_listingRequest' && item.show">
                    <p v-html="data.cadth['listing_request']"></p>
                </div>
                <!--Link Listing request end-->

                <!--Link effectiveness_evidences start-->
                <div class="content-onglets table"  v-else-if="key == 'detail_effectivenessEvidences' && item.show">
                    <table class="comparateur2" v-if="data.cadth['cadth_effectiveness_evidence'].length > 0">
                        <thead>
                            <tr>
                                <th width="10%" class="first" >Model type</th> 
                                <th width="10%" >ICER comparator</th>
                                <th width="10%" >ICER type</th>
                                <th width="10%" >ICER basecase (CAD)</th>
                                <th width="10%" >ICER lower (CAD)</th>
                                <th width="10%" >ICER upper (CAD)</th>
                                <th width="10%" >ICER probability </th> 
                                <th width="30%" class="last">CADTH comment on ICER</th> 
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, key) in data.cadth['cadth_effectiveness_evidence']" :key="'CadthEffectivenessEvidence_'+key"> 
                                <td> {{value['icer_model_type'] ? value['icer_model_type'] : '-'}} </td> 
                                <td> {{value['icer_comparator'] ? value['icer_comparator'] : '-'}} </td>
                                <td> {{value['icer_type'] ? value['icer_type'] : '-'}} </td>
                                <td> {{value['icer_basecase_text'] ? value['icer_basecase_text'] : '-'}} </td>
                                <td> {{value['icer_lower_text'] ? value['icer_lower_text'] : '-'}} </td>
                                <td> {{value['icer_upper_text'] ? value['icer_upper_text'] : '-'}} </td>
                                <td> {{value['icer_probability'] ? value['icer_probability'] : '-'}} </td>
                                <td class="textJustify">
                                    {{value['icer_cadth_comment'] ? strippedContent(value['icer_cadth_comment']) : '-' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="space" ></div>
                    <a class="btn btn-warning" style="color:#fff" :href="FULL_BASE_URL+'/pdf/view_pdf_popup/'+ $route.params.id +'/' + $route.params.agency" target="_blank" rel="noopener" > Full analysis</a>
                    <div class="space"></div>
                </div>
                <!--Link effectiveness_evidences end-->
                <!-- Section Detail End -->

                </div>
            </template>
               
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, strippedContent, check_detail_conclusion, key_documents } from '../../../utils'
import { url_prismaccess } from '@/app.config';

export default{
    name: 'cadth',
    components : {
        LeftSection,
        TreatmentLine,
        EconomicEvaluation,
        EvaluationEconomic,
        Conclusion,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_general_information : { 'title' : 'general_information', 'show' : true, 'enable' : false, 'orange_text':false},               
                detail_indication : { 'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false},               
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_MaSpac : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true },                
                detail_recommendation : {'title' : 'recommendation', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_reasons_recommendation : {'title' : 'reasons_recommendation', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_implementation : {'title' : 'Implementation considerations', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_ofNote : {'title' : 'of_note', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_background : {'title' : 'background', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_submissionHistory : {'title' : 'submission_history', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_recommendationSummary : {'title' : 'summary_cdec', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_costCostEffectiveness : {'title' : 'cost_cost_effectiveness', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_requestForClarification : {'title' : 'request_for_clarification', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },           
                detail_otherDiscussionPoint : {'title' : 'other_discussion_point', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_researchGaps : {'title' : 'research_gaps', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_key_milestones : {'title' : 'Key Milestones', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_listingRequest : {'title' : 'listing_request', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_effectivenessEvidences : {'title' : 'Cost effectiveness Evidence', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_key_documents : {'title' : 'documents', 'show' : true, 'enable' : false, 'orange_text':false },             
            },
        }
    },
    created(){
        // condition to show check box in left section [enable]

        if(this.data && this.data.cadth){                
            
            if(this.data['indication_en'])
            { 
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.guidance_id || this.data.status){
                this.list_check_box['detail_general_information'].enable = true
            }               
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  
            { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.data.cadth['cadth_type_amm'])
            { 
                this.list_check_box['detail_MaSpac'].enable = true
            }     
            if(this.check_detail_conclusion(this.data, this.$i18n.locale))
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }
            if(this.data.cadth['recommendation_manual'] || this.data.cadth['clinical_criteria'] || this.data.cadth['condition'])
            { 
                this.list_check_box['detail_recommendation'].enable = true
            } 
            if(this.data.cadth['reasons_recommendation'])
            { 
                this.list_check_box['detail_reasons_recommendation'].enable = true
            } 
            if(this.data.cadth['implementation'])
            { 
                this.list_check_box['detail_implementation'].enable = true
            } 
            if(this.data.cadth['of_note'])
            { 
                this.list_check_box['detail_ofNote'].enable = true
            } 
            if(this.data.cadth['of_note'])
            { 
                this.list_check_box['detail_ofNote'].enable = true
            } 
            if(this.data.cadth['background'])
            { 
                this.list_check_box['detail_background'].enable = true
            } 
            if(this.data.cadth['submission_history'])
            { 
                this.list_check_box['detail_submissionHistory'].enable = true
            } 
            if(this.data.cadth['summary_cdec'] || this.data.cadth['patient_input_information'] || this.data.cadth['summary_cdec_clinical_trials'] ||
                this.data.cadth['summary_cdec_outcomes'] || this.data.cadth['summary_cdec_manufacturer'] || this.data.cadth['summary_cdec_network'] ||
                this.data.cadth['summary_cdec_extension'] || this.data.cadth['summary_cdec_additional'] || this.data.cadth['summary_cdec_systematic'] ||
                this.data.cadth['summary_cdec_pharmacokinetic'] || this.data.cadth['summary_cdec_results'] || 
                this.data.cadth['summary_cdec_results_efficacy_effectiveness'] || this.data.cadth['summary_cdec_results_harms'] ||
                this.data.cadth['summary_cdec_results_indirect_treatment_comparison'] || this.data.cadth['summary_cdec_results_bioequivalences'])
            { 
                this.list_check_box['detail_recommendationSummary'].enable = true
            } 
            if(this.data.cadth['summary_cdec'] || this.data.cadth['patient_input_information'] || this.data.cadth['summary_cdec_clinical_trials'] ||
                this.data.cadth['summary_cdec_outcomes'] || this.data.cadth['summary_cdec_manufacturer'] || this.data.cadth['summary_cdec_network'] ||
                this.data.cadth['summary_cdec_extension'] || this.data.cadth['summary_cdec_additional'] || this.data.cadth['summary_cdec_systematic'] ||
                this.data.cadth['summary_cdec_pharmacokinetic'] || this.data.cadth['summary_cdec_results'] || 
                this.data.cadth['summary_cdec_results_efficacy_effectiveness'] || this.data.cadth['summary_cdec_results_harms'] ||
                this.data.cadth['summary_cdec_results_indirect_treatment_comparison'] || this.data.cadth['summary_cdec_results_bioequivalences'])
            { 
                this.list_check_box['detail_recommendationSummary'].enable = true
            } 
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
            if(this.data.cadth['cost_cost_effectiveness'])
            { 
                this.list_check_box['detail_costCostEffectiveness'].enable = true
            } 
            if(this.data.cadth['request_for_clarification'])
            { 
                this.list_check_box['detail_requestForClarification'].enable = true
            }
            if(this.data.cadth.evaluation_economic.length > 0) 
            { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }
            
        
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false
            } 
            if(this.data.cadth['other_discussion_point'])
            { 
                this.list_check_box['detail_otherDiscussionPoint'].enable = true
            }
            if(this.data.cadth['research_gaps'])
            { 
                this.list_check_box['detail_researchGaps'].enable = true
            }
            if(Object.keys(this.key_milestones).length > 0)
            { 
                this.list_check_box['detail_key_milestones'].enable = true
            }
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0)
            { 
                this.list_check_box['detail_key_documents'].enable = true
            }   
            if(this.data.cadth['listing_request'])
            { 
                this.list_check_box['detail_listingRequest'].enable = true
            }
            if(this.data.cadth['cadth_effectiveness_evidence'].length > 0 )
            { 
                this.list_check_box['detail_effectivenessEvidences'].enable = true
            }
        }
    },
    methods : {
       DDMMMYY, 
       strippedContent,
       check_detail_conclusion,
       key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        FULL_BASE_URL(){
            return url_prismaccess
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        },
        key_milestones(){
            let key_milestones = {}
            if(this.data.cadth['call_for_patient'] || this.data.cadth['call_for_patient_clarification']){
                key_milestones['call_for_patient'] = {}
                if(this.data.cadth['call_for_patient']){
                    key_milestones['call_for_patient']['date'] = this.data.cadth['call_for_patient']
                }
                if(this.data.cadth['call_for_patient_clarification']){
                    key_milestones['call_for_patient']['clarification'] = this.data.cadth['call_for_patient_clarification']
                }
            }
            if(this.data.cadth['patient_group'] || this.data.cadth['patient_group_clarification']){
                key_milestones['patient_group'] = {}
                if(this.data.cadth['patient_group']){
                    key_milestones['patient_group']['date'] = this.data.cadth['patient_group']
                }
                if(this.data.cadth['patient_group_clarification']){
                    key_milestones['patient_group']['clarification'] = this.data.cadth['patient_group_clarification']
                }
            }
            if(this.data.cadth['patient_input_summary'] || this.data.cadth['patient_input_summary_clarification']){
                key_milestones['patient_input_summary'] = {}
                if(this.data.cadth['patient_input_summary']){
                    key_milestones['patient_input_summary']['date'] = this.data.cadth['patient_input_summary']
                }
                if(this.data.cadth['patient_input_summary_clarification']){
                    key_milestones['patient_input_summary']['clarification'] = this.data.cadth['patient_input_summary_clarification']
                }
            }
            if(this.data.cadth['patient_group_comments'] || this.data.cadth['patient_group_comments_clarification']){
                key_milestones['patient_group_comments'] = {}
                if(this.data.cadth['patient_group_comments']){
                    key_milestones['patient_group_comments']['date'] = this.data.cadth['patient_group_comments']
                }
                if(this.data.cadth['patient_group_comments_clarification']){
                    key_milestones['patient_group_comments']['clarification'] = this.data.cadth['patient_group_comments_clarification']
                }
            }
            if(this.data.cadth['submission_received'] || this.data.cadth['submission_received_clarification']){
                key_milestones['submission_received'] = {}
                if(this.data.cadth['submission_received']){
                    key_milestones['submission_received']['date'] = this.data.cadth['submission_received']
                }
                if(this.data.cadth['submission_received_clarification']){
                    key_milestones['submission_received']['clarification'] = this.data.cadth['submission_received_clarification']
                }
            }
            if(this.data.cadth['submission_accepted'] || this.data.cadth['submission_accepted_clarification']){
                key_milestones['submission_accepted'] = {}
                if(this.data.cadth['submission_accepted']){
                    key_milestones['submission_accepted']['date'] = this.data.cadth['submission_accepted']
                }
                if(this.data.cadth['submission_accepted_clarification']){
                    key_milestones['submission_accepted']['clarification'] = this.data.cadth['submission_accepted_clarification']
                }
            }
            if(this.data.cadth['review_initiated'] || this.data.cadth['review_initiated_clarification']){
                key_milestones['review_initiated'] = {}
                if(this.data.cadth['review_initiated']){
                    key_milestones['review_initiated']['date'] = this.data.cadth['review_initiated']
                }
                if(this.data.cadth['review_initiated_clarification']){
                    key_milestones['review_initiated']['clarification'] = this.data.cadth['review_initiated_clarification']
                }
            }
            if(this.data.cadth['review_report'] || this.data.cadth['review_report_clarification']){
                key_milestones['review_report'] = {}
                if(this.data.cadth['review_report']){
                    key_milestones['review_report']['date'] = this.data.cadth['review_report']
                }
                if(this.data.cadth['review_report_clarification']){
                    key_milestones['review_report']['clarification'] = this.data.cadth['review_report_clarification']
                }
            }
            if(this.data.cadth['comment_application'] || this.data.cadth['comment_application_clarification']){
                key_milestones['comment_application'] = {}
                if(this.data.cadth['comment_application']){
                    key_milestones['comment_application']['date'] = this.data.cadth['comment_application']
                }
                if(this.data.cadth['comment_application_clarification']){
                    key_milestones['comment_application']['clarification'] = this.data.cadth['comment_application_clarification']
                }
            }
            if(this.data.cadth['redaction_requests'] || this.data.cadth['redaction_requests_clarification']){
                key_milestones['redaction_requests'] = {}
                if(this.data.cadth['redaction_requests']){
                    key_milestones['redaction_requests']['date'] = this.data.cadth['redaction_requests']
                }
                if(this.data.cadth['redaction_requests_clarification']){
                    key_milestones['redaction_requests']['clarification'] = this.data.cadth['redaction_requests_clarification']
                }
            }
            if(this.data.cadth['cdr_review_team'] || this.data.cadth['cdr_review_team_clarification']){
                key_milestones['cdr_review_team'] = {}
                if(this.data.cadth['cdr_review_team']){
                    key_milestones['cdr_review_team']['date'] = this.data.cadth['cdr_review_team']
                }
                if(this.data.cadth['cdr_review_team_clarification']){
                    key_milestones['cdr_review_team']['clarification'] = this.data.cadth['cdr_review_team_clarification']
                }
            }
            if(this.data.cadth['canadian_drug'] || this.data.cadth['canadian_drug_clarification']){
                key_milestones['canadian_drug'] = {}
                if(this.data.cadth['canadian_drug']){
                    key_milestones['canadian_drug']['date'] = this.data.cadth['canadian_drug']
                }
                if(this.data.cadth['canadian_drug_clarification']){
                    key_milestones['canadian_drug']['clarification'] = this.data.cadth['canadian_drug_clarification']
                }
            }
            if(this.data.cadth['cdec_recommendation'] || this.data.cadth['cdec_recommendation_clarification']){
                key_milestones['cdec_recommendation'] = {}
                if(this.data.cadth['cdec_recommendation']){
                    key_milestones['cdec_recommendation']['date'] = this.data.cadth['cdec_recommendation']
                }
                if(this.data.cadth['cdec_recommendation_clarification']){
                    key_milestones['cdec_recommendation']['clarification'] = this.data.cadth['cdec_recommendation_clarification']
                }
            }
            if(this.data.cadth['request_advice_received'] || this.data.cadth['request_advice_received_clarification']){
                key_milestones['request_advice_received'] = {}
                if(this.data.cadth['request_advice_received']){
                    key_milestones['request_advice_received']['date'] = this.data.cadth['request_advice_received']
                }
                if(this.data.cadth['request_advice_received_clarification']){
                    key_milestones['request_advice_received']['clarification'] = this.data.cadth['request_advice_received_clarification']
                }
            }
            if(this.data.cadth['manufacturer_informed'] || this.data.cadth['manufacturer_informed_clarification']){
                key_milestones['manufacturer_informed'] = {}
                if(this.data.cadth['manufacturer_informed']){
                    key_milestones['manufacturer_informed']['date'] = this.data.cadth['manufacturer_informed']
                }
                if(this.data.cadth['manufacturer_informed_clarification']){
                    key_milestones['manufacturer_informed']['clarification'] = this.data.cadth['manufacturer_informed_clarification']
                }
            }
            if(this.data.cadth['manufacturer_information'] || this.data.cadth['manufacturer_information_clarification']){
                key_milestones['manufacturer_information'] = {}
                if(this.data.cadth['manufacturer_information']){
                    key_milestones['manufacturer_information']['date'] = this.data.cadth['manufacturer_information']
                }
                if(this.data.cadth['manufacturer_information_clarification']){
                    key_milestones['manufacturer_information']['clarification'] = this.data.cadth['manufacturer_information_clarification']
                }
            }
            if(this.data.cadth['request_advice_initiated'] || this.data.cadth['request_advice_initiated_clarification']){
                key_milestones['request_advice_initiated'] = {}
                if(this.data.cadth['request_advice_initiated']){
                    key_milestones['request_advice_initiated']['date'] = this.data.cadth['request_advice_initiated']
                }
                if(this.data.cadth['request_advice_initiated_clarification']){
                    key_milestones['request_advice_initiated']['clarification'] = this.data.cadth['request_advice_initiated_clarification']
                }
            }
            if(this.data.cadth['draft_cdr_request'] || this.data.cadth['draft_cdr_request_clarification']){
                key_milestones['draft_cdr_request'] = {}
                if(this.data.cadth['draft_cdr_request']){
                    key_milestones['draft_cdr_request']['date'] = this.data.cadth['draft_cdr_request']
                }
                if(this.data.cadth['draft_cdr_request_clarification']){
                    key_milestones['draft_cdr_request']['clarification'] = this.data.cadth['draft_cdr_request_clarification']
                }
            }
            if(this.data.cadth['comments_applicant'] || this.data.cadth['comments_applicant_clarification']){
                key_milestones['comments_applicant'] = {}
                if(this.data.cadth['comments_applicant']){
                    key_milestones['comments_applicant']['date'] = this.data.cadth['comments_applicant']
                }
                if(this.data.cadth['comments_applicant_clarification']){
                    key_milestones['comments_applicant']['clarification'] = this.data.cadth['comments_applicant_clarification']
                }
            }
            if(this.data.cadth['redaction_requests_applicant'] || this.data.cadth['redaction_requests_applicant_clarification']){
                key_milestones['redaction_requests_applicant'] = {}
                if(this.data.cadth['redaction_requests_applicant']){
                    key_milestones['redaction_requests_applicant']['date'] = this.data.cadth['redaction_requests_applicant']
                }
                if(this.data.cadth['redaction_requests_applicant_clarification']){
                    key_milestones['redaction_requests_applicant']['clarification'] = this.data.cadth['redaction_requests_applicant_clarification']
                }
            }
            if(this.data.cadth['cdr_review_team_request'] || this.data.cadth['cdr_review_team_request_clarification']){
                key_milestones['cdr_review_team_request'] = {}
                if(this.data.cadth['cdr_review_team_request']){
                    key_milestones['cdr_review_team_request']['date'] = this.data.cadth['cdr_review_team_request']
                }
                if(this.data.cadth['cdr_review_team_request_clarification']){
                    key_milestones['cdr_review_team_request']['clarification'] = this.data.cadth['cdr_review_team_request_clarification']
                }
            }
            if(this.data.cadth['cdec_recommendation_request'] || this.data.cadth['cdec_recommendation_request_clarification']){
                key_milestones['cdec_recommendation_request'] = {}
                if(this.data.cadth['cdec_recommendation_request']){
                    key_milestones['cdec_recommendation_request']['date'] = this.data.cadth['cdec_recommendation_request']
                }
                if(this.data.cadth['cdec_recommendation_request_clarification']){
                    key_milestones['cdec_recommendation_request']['clarification'] = this.data.cadth['cdec_recommendation_request_clarification']
                }
            }
            if(this.data.cadth['embargo4_period'] || this.data.cadth['embargo4_period_clarification']){
                key_milestones['embargo4_period'] = {}
                if(this.data.cadth['embargo4_period']){
                    key_milestones['embargo4_period']['date'] = this.data.cadth['embargo4_period']
                }
                if(this.data.cadth['embargo4_period_clarification']){
                    key_milestones['embargo4_period']['clarification'] = this.data.cadth['embargo4_period_clarification']
                }
            }
            if(this.data.cadth['cdec_final_issued'] || this.data.cadth['cdec_final_issued_clarification']){
                key_milestones['cdec_final_issued'] = {}
                if(this.data.cadth['cdec_final_issued']){
                    key_milestones['cdec_final_issued']['date'] = this.data.cadth['cdec_final_issued']
                }
                if(this.data.cadth['cdec_final_issued_clarification']){
                    key_milestones['cdec_final_issued']['clarification'] = this.data.cadth['cdec_final_issued_clarification']
                }
            }
            if(this.data.cadth['cdec_final_posted'] || this.data.cadth['cdec_final_posted_clarification']){
                key_milestones['cdec_final_posted'] = {}
                if(this.data.cadth['cdec_final_posted']){
                    key_milestones['cdec_final_posted']['date'] = this.data.cadth['cdec_final_posted']
                }
                if(this.data.cadth['cdec_final_posted_clarification']){
                    key_milestones['cdec_final_posted']['clarification'] = this.data.cadth['cdec_final_posted_clarification']
                }
            }
            if(this.data.cadth['final_cdr'] || this.data.cadth['final_cdr_clarification']){
                key_milestones['final_cdr'] = {}
                if(this.data.cadth['final_cdr']){
                    key_milestones['final_cdr']['date'] = this.data.cadth['final_cdr']
                }
                if(this.data.cadth['final_cdr_clarification']){
                    key_milestones['final_cdr']['clarification'] = this.data.cadth['final_cdr_clarification']
                }
            }
            return key_milestones
        },        
    },
}

</script>

<style scoped>
</style>